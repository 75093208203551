.RPage {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  background-color: #292929;
  position: relative;
  z-index: 1;
  margin: 0;
}
.RPage .logo {
  height: 40px;
}
.RPage ul,
.Nav2 ul {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0;
  padding: 0;
}

.RPage li,
.Nav2 li {
  margin: 0 10px;
}

.wrapper {
  max-width: 100%;
  position: relative;
}
.wrapper .Search {
  width: 600px;
  height: 40px;
  border-radius: 30px;
  background-color: #292929;
  border-color: rgba(165, 165, 165, 0.612);
  border-style: solid;
  color: white;
  font-size: 17px;
  text-indent: 10px;
}
.wrapper .searchButton {
  position: absolute;
  right: 2%;
  top: 0;
  background: none;
  border: none;
  padding: 10px;
  cursor: pointer;
}
.icon {
  height: 25px;
}
.Nav2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding-left: 10px;
  font-size: 2ex;
  margin-left: 150px;
}
.searchResults {
  border-top-style: solid;
  border-color: #949494;
  border-width: 1px;
  margin-top: 20px;
}

.resultItem {
  margin-bottom: 20px;
}

a {
  text-decoration: none;
  color: white;
}

a:hover {
  text-decoration: underline;
}

.data {
  color: #949494;
  margin-left: 120px;
  width: 40%;
}

.link {
  color: #00b8ff;
}

.text {
  color: white;
  font-size: 30px;
}
